import styled from 'styled-components';

export const Wrapper = styled.ul`
	list-style: none;
	padding: 0;
	margin-top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;
